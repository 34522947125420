const images = {
  logo: require("../assets/images/hakka.png"),
  avt: require("../assets/images/avatar.jpg"),
  logKey: require("../assets/images/Reset password-pana.svg"),
  dashboard: require("../assets/images/Revenue-cuate.svg"),
  notFound: require("../assets/images/Oops 404 Error with a broken robot-cuate.svg"),
  notificationSound: require("../assets/notification/light-hearted-message-tone.mp3"),
};

export default images;
