const sidebarNav = [
  {
    link: "/",
    section: "dashboard",
    icon: "lucide:layout-dashboard", //width:"20"
    text: "Dashboard",
  },
  {
    link: "/customers",
    section: "customers",
    icon: "ph:users-bold",
    text: "Customers",
  },
  {
    link: "/users",
    section: "users",
    icon: "icon-park-outline:people",
    text: "Users",
  },
  {
    link: "/coupons",
    section: "coupons",
    icon: "nimbus:discount-circle",
    text: "Coupons",
  },
  {
    link: "/customers-base-list",
    section: "customersbase",
    icon: "icon-park-outline:people",
    text: "CustomersBase",
  },
  {
    link: "/email-marketing",
    section: "email",
    icon: "carbon:analytics",
    text: "Emails",
  },
];

export default sidebarNav;
