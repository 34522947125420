import { errorFunction } from "./alert";

export const makeFetchRequest = async (url: string, options?: RequestInit) => {
  try {
    const token = localStorage.getItem("accessToken") || null;
    const cleanedToken = token ? token.replace(/"/g, "") : null;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${cleanedToken}`);

    const response = await fetch(url, {
      headers: headers,
      ...options,
    });

    if (!response.ok) {
      errorFunction("Error fetching the data");
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error making fetch request:", error);
    errorFunction("Error making fetch request:");
    throw error;
  }
};

// apiUtils.ts

export const makePostFormRequest = async (url: string, formData: FormData) => {
  try {
    const token = localStorage.getItem("accessToken") || null;
    const cleanedToken = token ? token.replace(/"/g, "") : null;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${cleanedToken}`);

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error making POST request:", error);
    throw error;
  }
};

export const makePatchJSONRequest = async (url: string, reqBody: any) => {
  try {
    const token = localStorage.getItem("accessToken") || null;
    const cleanedToken = token ? token.replace(/"/g, "") : null;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${cleanedToken}`);

    const response = await fetch(url, {
      method: "PATCH",
      headers: headers,
      body: reqBody,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error making POST request:", error);
    throw error;
  }
};
