// Notification.js

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import images from "../../../../constants/images";
import { IUserNotificationsTable } from "../../../../interfaces/Itable";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {
  makeFetchRequest,
  makePatchJSONRequest,
} from "../../../../utils/fetch";
import { errorFunction, successFunction } from "../../../../utils/alert";

const NotificationContainer = styled.div`
  position: relative;
`;

const NotificationButton = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  margin-right: 20px;

  &:hover {
    background-color: #e0e0e0;
    border-color: #333;
  }

  .icon {
    color: #333;
    font-size: 18px;
  }

  &.open {
    background-color: #e0e0e0;
    border-color: #fff;

    .icon {
      color: #fff;
    }
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff3b30;
  color: #fff;
  border-radius: 50%;
  padding: 6px;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #ff3b30;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const NotificationListOpen = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 1000%;
  max-width: 320px; /* Adjusted max-width for a wider dropdown */
  max-height: 600px;
  overflow-y: auto;
  opacity: 1;
  visibility: visible;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  z-index: 100;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-top: 3px solid #3498db;
  cursor: pointer;

  &:hover {
    border-color: #3498db;
  }
`;

const NotificationListClosed = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 100%;
  max-width: 240px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  z-index: 100;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-top: 3px solid #3498db;

  &:hover {
    border-color: #3498db;
  }
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease-in-out;
  border-radius: 8px;
  margin-bottom: 8px;

  &:hover {
    background-color: #f2f2f2;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const NotificationItemImage = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

const NotificationItemContent = styled.div`
  flex: 1;

  .notificationItemTitle {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .notificationItemDesc {
    color: #666;
    margin-bottom: 4px;
    max-width: 200px; /* Adjusted max-width for better content display */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .notificationItemTime {
    color: #999;
    font-size: 12px;
  }

  .notificationItemUnread {
    width: 8px;
    height: 8px;
    background-color: #ff3b30;
    border-radius: 50%;
    margin-left: 4px;
  }
`;

const Notification = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(3);
  const [notifications, setNotifications] = useState<IUserNotificationsTable[]>(
    []
  );
  const username = localStorage.getItem("username");

  const fetchUserNotifications = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/notification-app/notifications`;
      const response = await makeFetchRequest(apiUrl);

      setNotifications(response?.items);
      setNotificationCount(response?.total);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserNotifications();

    const socket = new WebSocket(
      `${process.env.REACT_APP_SOCKET_URL}/api/v1/notification-app/ws/${username}`
    );
    socket.onopen = () => {
      console.log("Connection established successfully");
    };

    socket.onclose = () => {
      console.log("Connection closed successfully");
    };

    const handleSocketMessage = (event: any) => {
      // Handle the socket message here
      new Audio(images.notificationSound).play();
      successFunction("Notification received");
    };

    socket.onmessage = handleSocketMessage;

    return () => {
      // Clean up the socket connection on component unmount
      socket.close();
    };
  }, []);
  const handleNotificationClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNotificationItemClick = (item: IUserNotificationsTable) => {
    try {
      makePatchJSONRequest(
        `${process.env.REACT_APP_BASE_URL}/api/v1/notification-app/read-notification/${item?.id}`,
        {}
      );
      fetchUserNotifications();
    } catch (error: any) {
      errorFunction("Error reading notification");
    }
    setIsOpen(false);
    switch (item?.notification_type) {
      case "COUPONS":
        navigate("/coupons");
        break;
      case "USERS":
        navigate("/users");
        break;
      case "CUSTOMERS":
        navigate("/customers");
        break;
      default:
        navigate("/");
    }
  };

  return (
    <>
      <NotificationContainer>
        <NotificationButton
          className={`${isOpen ? "open" : ""}`}
          onClick={handleNotificationClick}
        >
          <Icon className="icon" icon="fa:bell" width="24" />
          {notificationCount > 0 && (
            <NotificationBadge>{notificationCount}</NotificationBadge>
          )}
        </NotificationButton>
        {isOpen ? (
          <NotificationListOpen>
            {notifications.map((item, index) => (
              <Link
                to={`${
                  item?.notification_type === "CUSTOMER"
                    ? "customers"
                    : "coupons"
                }`}
              >
                <NotificationItem
                  key={index}
                  onClick={() => handleNotificationItemClick(item)}
                >
                  <NotificationItemImage>
                    <img src={images.avt} alt={item?.id} />
                  </NotificationItemImage>
                  <NotificationItemContent>
                    <div className="notificationItemTitle">{item?.message}</div>
                    <div className="notificationItemDesc">
                      {item?.notification_type}
                    </div>
                    <div className="notificationItemTime">
                      {moment(item?.created_at).fromNow()}
                    </div>
                    {item?.is_read && (
                      <div className="notificationItemUnread"></div>
                    )}
                  </NotificationItemContent>
                </NotificationItem>
              </Link>
            ))}
          </NotificationListOpen>
        ) : (
          <NotificationListClosed></NotificationListClosed>
        )}
      </NotificationContainer>
    </>
  );
};

export default Notification;
