import classes from "./Loader.module.scss";
import React, { useEffect, useState } from "react";
const Logo = require("../../../assets/images/hakka.png");

const Loader = () => {
  const [loadingText, setLoadingText] = useState("Loading");

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText === "Loading...") {
          return "Almost there";
        } else if (prevText === "Almost there...") {
          return "Almost there";
        } else {
          return prevText + ".";
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.loader_container}>
      <div className={classes.loader_animation}>
        <img
          src={Logo}
          alt="Restaurant Logo"
          className={classes.restaurant_logo}
        />
      </div>
      <p className={classes.loader_text}>{loadingText}</p>
    </div>
  );
};

export default Loader;
