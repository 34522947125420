import React from "react";
import { useLocalStorage } from "usehooks-ts";

type TContext = {
  isLogin: boolean;
  toggleLogin: () => void;
  accessToken: string | null;
  logout: () => void;
};

const LoginContext = React.createContext<TContext>({
  isLogin: false,
  toggleLogin: () => {},
  accessToken: localStorage.getItem("accessToken"),
  logout: () => {},
});

export const LoginContextProvider: React.FC = (props) => {
  const [isLogin, setIsLogin] = useLocalStorage("isLogin", false);
  const [accessToken, setAccessToken] = useLocalStorage<string | null>(
    "accessToken",
    null
  );

  const toggleLogin = () => {
    setIsLogin((prev) => !prev);
  };

  const logout = () => {
    setAccessToken(null);
    setIsLogin(false);
    localStorage.clear();
  };

  const loginValue: TContext = {
    isLogin: isLogin,
    accessToken: accessToken,
    toggleLogin: toggleLogin,
    logout: logout,
  };

  return (
    <LoginContext.Provider value={loginValue}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
