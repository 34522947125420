import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import Loader from "./components/UI/loader/Loader";
import "./scss/App.scss";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Customers = React.lazy(() => import("./pages/Customers"));
const CustomersBase = React.lazy(() => import("./pages/CustomersBase"));
const Users = React.lazy(() => import("./pages/Users"));
const UsersEdit = React.lazy(() => import("./pages/UsersEdit"));
const Coupons = React.lazy(() => import("./pages/Coupons"));
const EmailMarketing = React.lazy(() => import("./pages/EmailMarketing"));
const EmailTemplate = React.lazy(
  () => import("./components/email/EmailTemplate")
);
const NotFound = React.lazy(() => import("./pages/NotFound"));
const BlankPage = React.lazy(() => import("./pages/BlankPage"));
const Login = React.lazy(() => import("./pages/Login"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers-base-list" element={<CustomersBase />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/:userId" element={<UsersEdit />} />
              <Route path="/orders" element={<BlankPage />} />
              <Route path="/analytics" element={<BlankPage />} />
              <Route path="/coupons" element={<Coupons />} />
              <Route path="/discount" element={<BlankPage />} />
              <Route path="/email-marketing" element={<EmailMarketing />} />
              <Route path="/email-template" element={<EmailTemplate />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
