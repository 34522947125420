import classes from "./Profile.module.scss";

function Profile() {
  const username = localStorage.getItem("username");
  const photo = localStorage.getItem("photo") || "";

  return (
    <div className={classes.profile}>
      <div className={classes.profile__avatar}>
        <img src={photo} alt="avatar" />
      </div>
      <div className={classes.profile__info}>
        <p className={classes.profile__userName}>{username}</p>
      </div>
    </div>
  );
}

export default Profile;
