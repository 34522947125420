import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { images } from "../../constants";
import sidebarNav from "../../config/sidebarNav";
import SidebarContext from "../../store/sidebarContext";
import LoginContext from "../../store/loginContext";
import { Icon } from "@iconify/react";
import classes from "./Sidebar.module.scss";
import { successFunction } from "../../utils/alert";

function Sidebar() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowSize();
  const location = useLocation();
  const sidebarCtx = useContext(SidebarContext);
  const loginCtx = useContext(LoginContext);
  const { t } = useTranslation();
  const role = localStorage.getItem("role") || "USER";
  let filteredSidebarNav: any;
  switch (role) {
    case "ADMIN":
    case "SUPERUSER":
      // For Admin or Superuser, show all menu items
      filteredSidebarNav = sidebarNav;
      break;

    case "CASHIER":
      // For Cashier, show only 'Customers' and 'Coupons'
      filteredSidebarNav = sidebarNav.filter(
        (nav) => nav.section === "customers" || nav.section === "coupons"
      );
      break;

    case "STAFF":
      // For Staff, show only 'Customers', 'Coupons', and 'Users'
      filteredSidebarNav = sidebarNav.filter(
        (nav) =>
          nav.section === "customers" ||
          nav.section === "coupons" ||
          nav.section === "users"
      );
      break;

    default:
      // For other roles or unknown roles, show an empty array (no menu items)
      filteredSidebarNav = [];
  }

  function openSidebarHandler() {
    //for width>768(tablet size) if sidebar was open in width<768 was opened too.
    //just in case of tablet size and smaller then, sidebar__open can added.
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  function logoutHandler() {
    openSidebarHandler();
    loginCtx.logout();
    successFunction("Logged out successfully");
  }

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div
      className={`${classes.sidebar} ${
        !sidebarCtx.isOpen && classes.sidebar_close
      }`}
    >
      <div className={classes.sidebar__logo}>
        <Link to="/">
          <img src={images.logo} alt="hakka" />
        </Link>
      </div>
      <div className={classes.sidebar__menu}>
        {filteredSidebarNav.map((nav: any, index: any) => (
          <Link
            to={nav.link}
            key={`nav-${index}`}
            className={`${classes.sidebar__menu__item} ${
              activeIndex === index && classes.active
            }`}
            onClick={openSidebarHandler}
          >
            <div className={classes.sidebar__menu__item__icon}>
              <Icon icon={nav.icon} />
            </div>
            <div className={classes.sidebar__menu__item__txt}>
              {t(nav.section)}
            </div>
          </Link>
        ))}
      </div>

      <div className={[classes.sidebar__menu, classes.logout].join("")}>
        <Link
          to="/login"
          className={classes.sidebar__menu__item}
          onClick={logoutHandler}
        >
          <div className={classes.sidebar__menu__item__icon}>
            <Icon icon="tabler:logout" />
          </div>
          <div className={classes.sidebar__menu__item__txt}>{t("logout")}</div>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
