import React, { useContext, useEffect, useMemo, useState } from "react";
import LoginContext from "../store/loginContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { axiosAuthInstance } from "../utils/axios";
import { errorFunction } from "../utils/alert";

const AuthLayout: React.FC = () => {
  const location = useLocation();
  const loginCtx = useContext(LoginContext);
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  const validateToken = useMemo(
    () => async (accessToken: string | null) => {
      try {
        if (!accessToken) {
          console.log("Access token is missing.");
          setIsTokenValid(false);
          return;
        }

        // Make an API call to validate the token
        const response = await axiosAuthInstance(accessToken).post(
          "/api/v1/auth-app/validate-token"
        );
        if (response?.data === false) {
          errorFunction("Please login again");
        }

        setIsTokenValid(response?.data);
      } catch (error) {
        console.error("Error validating token:", error);
        errorFunction("Please login again");
        setIsTokenValid(false);
      }
    },
    []
  );

  useEffect(() => {
    validateToken(loginCtx.accessToken);
  }, [loginCtx.accessToken, validateToken]);

  useEffect(() => {
    if (loginCtx.isLogin && isTokenValid !== null && !isTokenValid) {
      // Log out the user if the token is not valid
      loginCtx.logout();
    }
  }, [loginCtx.isLogin, isTokenValid, loginCtx.logout]);

  return loginCtx.isLogin ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default AuthLayout;
